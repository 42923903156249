@media (max-width: 800px) {
  .modal-container {
    .autofill-wrapper {
      overflow-x: hidden;
      .header-wrapper {
        width: 100%;

        .dropdown-container {
          width: 304px;
          right: unset;

          .date-container {
            text-align: center;

            .header-date {
              height: 50px;
            }
          }
        }
      }

      .autofill-body-wrapper {
        .prompt-wrapper {
          .buttons-wrapper {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (max-height: 400px) {
  .modal-container {
    .autofill-wrapper {
      margin-top: 2%;
      height: 94%;

      .header-wrapper {
        .dropdown-container {
          height: 90%;
          bottom: unset;
          top: 5%;
        }
      }
    }
  }
}
